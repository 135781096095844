import { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Box from '@mui/material/Box';

import { useStore } from '~/components/Hook';
import Job from './Job';
import './TabCss.css';
import Button from '~/components/Button';

import classNames from 'classnames/bind';
import styles from './TabCss.module.scss';
import { useMemo } from 'react';
import { handleUpdateJob, ReportJob } from '~/services/jobService';
import { DateToJSstring } from '~/ulti/helper';
import SnackbarButton from './SnackbarButton';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from 'array-move';

const cx = classNames.bind(styles);
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        className: 'Tab',
    };
}

function TabCss() {
    const cache = createCache({
        key: 'css',
        prepend: true,
    });
    const store = useStore();
    const [sortArray, setSortArray] = useState([
        { prop: 'Link', display: 'Đã nộp bài' },
        { prop: 'TenDuAn', display: 'Dự Án' },
        { prop: 'DeadLine', display: 'DeadLine' },
        { prop: 'TenCongViec', display: 'Công Việc' },
        { prop: 'status', display: 'Tình trạng' },
    ]);
    const onSortEnd = (oldIndex, newIndex) => {
        setSortArray((array) => arrayMove(array, oldIndex, newIndex));
    };
    function compare(a, b) {
        for (let selectedIndex = 0; selectedIndex < sortArray.length; selectedIndex++) {
            const propSort = sortArray[selectedIndex].prop;
            if (propSort === 'Link') {
                if (a.Link !== null && b.Link === null) return 1;
                if (a.Link === null && b.Link !== null) return -1;
            } else {
                if (a[propSort] < b[propSort]) {
                    return -1;
                }
                if (a[propSort] > b[propSort]) {
                    return 1;
                }
            }
        }
        return 0;
    }
    const jobs = store.storage.data.jobs.sort(compare);
    //console.log(jobs);
    // console.log(jobs[1].status, jobs[2].status, jobs[1].status < jobs[2].status);
    //console.log(jobs[5].Link !== null && jobs[6].Link === null);
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [stt, setSTT] = useState(0);
    const jobWillReport = jobs.reduce((sum, job) => {
        if (job.WorkToday === 'TRUE') return sum + 1;
        return sum;
    }, 0);
    const jobReported = jobs.reduce((sum, job) => {
        if (job.Reported === DateToJSstring(Date.now())) {
            return sum + 1;
        }
        return sum;
    }, 0);
    const total = useMemo(() => {
        const kq = jobs.reduce((sum, job) => {
            if (job.WillReport && job.WorkToday === 'TRUE') return sum + job.TimeToDay;
            return sum;
        }, 0);
        return kq;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stt]);
    // console.log('tab re-render', value, stt);
    const [disabled, setDisabled] = useState(false);
    const handleSubmit = async () => {
        setDisabled(true);
        for (const jobItem of jobs) {
            if (jobItem.WillReport && jobItem.TimeToDay && jobItem.WorkToday === 'TRUE') {
                //if (jobItem.WorkToday === 'TRUE') {
                const res = await ReportJob(jobItem);
                handleUpdateJob(store.storage, res);
                console.dir(jobItem);
            }
        }
        setDisabled(false);
        //setSTT((pre) => pre + 1);
    };
    // return a promise
    const copyToClipboard = (textToCopy) => {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement('textarea');
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = 'fixed';
            textArea.style.left = '-999999px';
            textArea.style.top = '-999999px';
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    };
    const handleCopyReport = () => {
        var report = '* CÔNG VIỆC TRONG NGÀY :\n';
        for (const jobItem of jobs) {
            if (jobItem.Reported === DateToJSstring(Date.now())) {
                report +=
                    '        +' +
                    jobItem.TenDuAn +
                    ' - ' +
                    jobItem.TenCongViec +
                    ' : Hoàn thành ' +
                    jobItem.PhanTramHienTai +
                    '%' +
                    '\n';
            }
        }
        report += '* KẾ HOẠCH NGÀY MAI :\n        +';
        //console.log(window.isSecureContext);
        copyToClipboard(report);
        //navigator.clipboard.writeText(report);
    };
    return (
        <CacheProvider value={cache}>
            <div className="orderWrapper">
                <h5>*Sắp xếp theo :</h5>
                <SortableList onSortEnd={onSortEnd} className="list" draggedItemClassName="dragged">
                    {sortArray.map((item) => (
                        <SortableItem key={item.prop}>
                            <div className="item">
                                <h4>{item.display}</h4>
                            </div>
                        </SortableItem>
                    ))}
                </SortableList>
            </div>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
                    <Tab label="CÔNG VIỆC ĐƯỢC GIAO" {...a11yProps(0)} />
                    <Tab label="CÔNG VIỆC TRONG NGÀY" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {/* <div>
                    <CircularProgressWithLabel value={30} />
                </div> */}
                {jobs.map((jobItem) => {
                    return <Job key={jobItem.MaCongViec} data={jobItem} setSTT={setSTT}></Job>;
                })}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {jobWillReport > 0 && (
                    <div>
                        <h2 className={cx('textUnderline')}>* BÁO CÁO :</h2>
                        {jobs.map((jobItem) => {
                            if (jobItem.WorkToday === 'TRUE') {
                                return <Job inday key={jobItem.MaCongViec} data={jobItem} setSTT={setSTT}></Job>;
                            }
                            return null;
                        })}
                        <div className={cx('total')}>
                            <h3 className={cx('floatRight')}>Tổng thời gian : {total}h</h3>
                            <div className={cx('button')}>
                                <Button disabled={disabled} primary onClick={handleSubmit}>
                                    Báo cáo
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {jobReported > 0 && (
                    <div>
                        <h2 className={cx('textUnderline')}>* CÔNG VIỆC HÔM NAY :</h2>
                        {jobs.map((jobItem) => {
                            if (jobItem.Reported === DateToJSstring(Date.now())) {
                                return (
                                    <Job inday key={jobItem.MaCongViec} data={jobItem} setSTT={setSTT} reported></Job>
                                );
                            }
                            return null;
                        })}
                        <div className={cx('reported')}>
                            <Button primary to="/timesheet">
                                TimeSheet
                            </Button>
                            {/* <Button text onClick={handleCopyReport}>
                                Copy qua Zalo
                            </Button> */}
                            <SnackbarButton onClick={handleCopyReport} />
                        </div>
                    </div>
                )}
            </TabPanel>
        </CacheProvider>
    );
}

export default TabCss;
