import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { useStore } from '~/components/Hook';
import { tryGetAcc } from '~/services/authService';
import { publicRoutes } from '~/routes';
import { DefaultLayout } from '~/layouts';
import { useEffect } from 'react';

function App() {
    const store = useStore();
    useEffect(() => {
        console.log('version 1.1');
        const loadUser = async () => {
            const payload = await tryGetAcc();
            if (payload) {
                store.storage.setData({ jobs: payload.jobs });
                store.dispatch(store.actions.setAcc(payload));
            }
        };
        loadUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {/* {!store.state.user && <BackGroundVideo id="hG0p69cxArI"></BackGroundVideo>} */}
            <Router>
                <div className="App">
                    <Routes>
                        {publicRoutes.map((route, index) => {
                            const Layout = route.layout || DefaultLayout;
                            const Page = route.component;
                            return (
                                <Route
                                    key={index}
                                    path={route.path}
                                    element={
                                        <Layout>
                                            <Page />
                                        </Layout>
                                    }
                                />
                            );
                        })}
                    </Routes>
                </div>
            </Router>
        </>
    );
}

export default App;
